export default {
  text: '#202020',
  primary: '#011227',
  primaryLight: '#5f82ad',
  secondary: '#172536',
  secondaryLight: '#5ac3ec',
  secondaryDark: '#0f62ca',
  secondaryDarker: '#175b8f',
  background: '#ffffff',
  backgroundSecondary: '#005a87',
  light: '#FFF',
  dark: '#010000'
}
