export default {
  heading: {
    fontFamily: 'heading',
    fontWeight: 'heading',
    lineHeight: 'heading'
    // textTransform: 'capitalize',
  },
  title: {
    fontSize: ['2rem', '2.5rem', '3rem', '3.5rem'],
    fontWeight: 'bold',
    // textTransform: 'capitalize',
    color: 'secondaryDark'
  },
  subtitle: {
    fontSize: ['1.25rem', '1.5rem', '1.75rem', '2rem'],
    fontWeight: '600',
    color: 'primary'
  },
  text: {
    fontWeight: '300',
    fontSize: '1rem',
    lineHeight: '1.7',
    color: 'inherit'
  },
  navLink: {},
  link: {
    textDecoration: 'none',
    color: 'unset'
  }
}
